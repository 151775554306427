import React from 'react';

const Logopart = () => {
  return (
    <div className="backtop">

    <div className="container logopart">
    <div className="row">
      <div className="col-sm-12 distance">
        {/*<img className="logoimg" src='logo.png' alt=""/>*/}
        <img className="logosvg" src='icons/logo.svg' alt=""/>
        <img src='icons/slogan.svg' alt=""/>
      </div>

    </div>
  </div>
    </div>
  );
}

export default Logopart;
