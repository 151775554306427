const ser_ru = [
  {id:"1",logo:"icons/sync.svg",name:"На ваш заказ", text:"В зависимости от ваших желаний и усмотрений создаём программы для Веб & Андроид и Иос платформах."},
  {id:"2",logo:"icons/business.svg",name:"Корпоративный сайт", text:"Серьёзным видом представительства компании в Интернете являются корпоративные сайты."},
  {id:"3",logo:"icons/person.svg",name:"Персональные сайты", text:"В сайте находится определенная информация про личности владельца этого сайта, главная информация контакты и т.д."},
  {id:"4",logo:"icons/camera.svg",name:"Сайт-витрина", text:"Бюджетным вариантом рекламы вашей компании является сайт-витрина. Главное назначение сайта-витрины – продавать."},
  {id:"5",logo:"icons/store.svg",name:"Интернет-магазин", text:"Ваш магазин 7/24 будет открытым и вы сможете продавать свои товары в любое время суток через интернет."},
  {id:"6",logo:"icons/like.svg",name:"Социальная сеть", text:"Платформа, приложения и веб-сайт, предназначенные для построения отражения социальных взаимоотношений в Интернете."},
  {id:"7",logo:"icons/game.svg",name:"Игры", text:"Вид осмысленной непродуктивной деятельности, где мотив лежит не в ее результате, а в самом процессе."},
  {id:"8",logo:"icons/bookkeeper.svg",name:"Бухгалтерия", text:"Электронная бухгалтерия управленческой задачей является сбор и обработка полной и достоверной информации о субъекте."},
  {id:"9",logo:"icons/forum.svg",name:"Форум", text:"Суть работы форума заключается в создании пользователями своих тем с их последующим обсуждением, путём размещения сообщений."},
  {id:"10",logo:"icons/autonomy.svg",name:"Автономия", text:"Переведения определенной физической задачи в цифровую программу. Экономьте время, расходы, повысьте эффективность."},
  {id:"11",logo:"icons/depo.svg",name:"Склад", text:"Наш уникальный продукт который поможет вам управлять вашим складом компании в режиме онлайн."},
  {id:"12",logo:"icons/rent.svg",name:"Аренда", text:"Наш уникальный продукт который позволяет сдавать в аренду продукты и  возможность отслеживать весь этот процесс."},
  {id:"13",logo:"icons/cloud.svg",name:"Информационный", text:"Виртуальный массив информации, включающий в себя множество различных тематических разделов меньшего размера."},
  {id:"14",logo:"icons/remote.svg",name:"Удаленный доступ", text:"Предоставляет пользователю удаленный доступ к тому к чему вы захотите. Например открыть дверь гаража или свет дома."},
  {id:"15",logo:"icons/school.svg",name:"Обучение", text:"Онлайн обучение имеет ряд преимуществ – индивидуальный темп, свобода, гибкость, доступность, социальное равноправие."},
  {id:"16",logo:"icons/portal.svg",name:"Порталы", text:"Наиболее мощный и самый сложный вид сетевого ресурса, который может быть посвящен как одной теме, так и нескольким."},
  {id:"17",logo:"icons/sharing.svg",name:"Обмен файлами", text:"Удовлетворяющие потребности пользователей в совместной работе данных как для небольших, так и для крупных предприятий."},
  {id:"18",logo:"icons/world.svg",name:"Новостные", text:"Платформы служащие для больших аудиторий, занимающиеся оповещением информацией глобальных новостей."},
  {id:"19",logo:"icons/library.svg",name:"Электронная библиотека", text:"Упорядоченная коллекция разнородных книг, журналов, электронных документов,  снабжённых средствами навигации и поиска."},
  {id:"20",logo:"icons/pencil.svg",name:"Блог", text:"Журнал событий, дневник, основное содержимое которого регулярно добавляемые записи, изображения, мультимедиа."},
  {id:"21",logo:"icons/cognitive.svg",name:"Познавательные", text:"Познавательные платформы в которых публикуются полезные информации от разных сфер для пользователей."}
]
export default ser_ru;
