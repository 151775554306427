import './App.css';
import React from 'react';
import { HashRouter, Route, Switch} from "react-router-dom";

import NotFound from './components/404';
import English from './lang/english'
import Turkish from './lang/turkish'
import Russian from './lang/russian'
import 'bootstrap/dist/css/bootstrap.min.css'

class App extends React.Component {

  render(){
    return (
          <HashRouter>
                <Switch>
                    <Route exact path="/" component={English} />
                    <Route exact path="/tr" component={Turkish} />
                    <Route exact path="/ru" component={Russian} />
                    <Route path="*" component={NotFound} />
                </Switch>
          </HashRouter>

    );
    }
}

export default App;
