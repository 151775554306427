const ser_en = [
  {id:"1",logo:"icons/sync.svg",name:"Custom order", text:"Dependencies on your desire for discretion create programs for the Web & Android & Ios platforms."},
  {id:"2",logo:"icons/business.svg",name:"Corporate", text:"A serious type of representation of the company on the Internet are corporate websites."},
  {id:"3",logo:"icons/person.svg",name:"Personal", text:"The site contains certain information about the identity of the owner of this site, main information contacts etc."},
  {id:"4",logo:"icons/camera.svg",name:"Showcase", text:"The budget option for advertising your company is a website-showcase. The main purpose of the showcases is to sell."},
  {id:"5",logo:"icons/store.svg",name:"Electronic Commerce", text:"Your store 7/24 will be open and you will be able to sell your products at any time of the day via the Internet."},
  {id:"6",logo:"icons/like.svg",name:"Social Network", text:"A platform, applications and a website designed to build a reflection of social relationships on the Internet."},
  {id:"7",logo:"icons/game.svg",name:"Game", text:"The kind of meaningful, unproductive activity, where the motive lies not in its result, but in the process itself."},
  {id:"8",logo:"icons/bookkeeper.svg",name:"Accounting", text:"Electronic accounting management task is to collect and process complete and reliable information about the subject."},
  {id:"9",logo:"icons/forum.svg",name:"Forum", text:"The essence of the forum is to users create of their topics with their subsequent discussion, by posting messages."},
  {id:"10",logo:"icons/autonomy.svg",name:"Autonomy", text:"Translating a specific physical task into a digital program. Save time, expenses, improve efficiency."},
  {id:"11",logo:"icons/depo.svg",name:"Stock", text:"Our unique product that will help you manage your company warehouse via by online in realtime."},
  {id:"12",logo:"icons/rent.svg",name:"Rent", text:"Our unique product that allows you to lease products and the ability to track the whole process."},
  {id:"13",logo:"icons/cloud.svg",name:"Information", text:"A virtual array of information, which includes many different thematic sections of a smaller size."},
  {id:"14",logo:"icons/remote.svg",name:"Remote Access", text:"Provides the user with remote access to what you want. For example, open the garage door or the house light."},
  {id:"15",logo:"icons/school.svg",name:"Education", text:"Online education has a number of advantages - individual pace, freedom, flexibility, accessibility, social equity."},
  {id:"16",logo:"icons/portal.svg",name:"Portal", text:"The most powerful and most complex kind of network resource, which can be devoted to one topic, and several."},
  {id:"17",logo:"icons/sharing.svg",name:"Data sharing", text:"Satisfying the needs of users in the joint work of data for both small and large enterprises."},
  {id:"18",logo:"icons/world.svg",name:"News", text:"Platforms serving for large audiences, engaged in the notification of global news. Can be implemented for Web and Mobile platforms."},
  {id:"19",logo:"icons/library.svg",name:"Digital Library", text:"An ordered collection of dissimilar books, magazines, electronic documents, equipped with navigation and search tools."},
  {id:"20",logo:"icons/pencil.svg",name:"Blog", text:"The event log, a diary whose main content is regularly added records, images, multimedia."},
  {id:"21",logo:"icons/cognitive.svg",name:"Cognitive", text:"Cognitive platforms in which useful information is published from different areas for users."}
]
export default ser_en;
