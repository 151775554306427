import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Languages from '../components/languages';
import Logopart from '../components/logopart';
import Social from '../components/social'
import Services from '../components/services';
import Partners from '../components/solution_partners';
import Contact from '../components/contact';
import Footer from '../components/footer';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_ru from '../jsons/services/s_ru';

class Russian extends React.Component {

  render(){
    return (
          <div>
               <Route exact path="/ru" component={() => <Languages lang="Русский" />} />
               <Route exact path="/ru" component={() => <Logopart />} />
               <Route exact path="/ru" component={() => <Social keepintouch={"быстрая связь"}/>}/>
               <Route exact path="/ru" component={() => <Services key={ser_ru.map(sss => (sss.id))} langdata={ser_ru} diname="услуги_программирование "/>} />
               <Route exact path="/ru" component={() => <Contact diname="контакты" loc1="Главный офис: Icmeler Mah. Altunay Sk. №: 33/33. Tuzla, Istanbul / Turkey" loc2="Скоро: Dubai / UAE"/>} />
               <Route exact path="/ru" component={() => <Footer footerdata="Все права защищены" />} />
          </div>

    );
    }
}

export default Russian;
