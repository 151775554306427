const Contact = (props) => {
  return (
    <div className="container">
      <p className="dirsection">/{props.diname}</p>
      <div className="contacts">
        <div className="col-12 row ">
          <div className="col-md-8 contacts-item">
            {props.loc1} <br></br>  {props.loc2}
          </div>
          <div className="col-md-4 contacts-item">
            info@buart.com.tr <br></br>  Telegram: @buartsoft
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
