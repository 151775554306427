const ser_tr = [
  {id:"1",logo:"icons/sync.svg",name:"İsteğinize Yönelik Yazılım", text:"Sizin isteğinize yönelik istenilen yazılım projesini Web & Android & Ios platfomlara yönelik gerçekleştirmekteyiz."},
  {id:"2",logo:"icons/business.svg",name:"Kurumsal Siteler", text:"Sektörde ki şirketlerin kurumsal siteleridir. Bu sitelerde şirketin profil, sektör, hizmet, iletişim, gibi bilgi taşıyan sayfalar bulunmaktadır."},
  {id:"3",logo:"icons/person.svg",name:"Kişisel Siteler", text:"Bir kişiyi tanıtmak için kullanılan sitelerdir. Genel olarak kişinin özlük bilgileri, fotoğrafları ve iletişim bilgileri site içereğinde yer almaktadır."},
  {id:"4",logo:"icons/camera.svg",name:"Tanıtım Siteleri", text:"Belirli bir konu veya ürün hakkında bilgi içereği tanıtan, ziyaretcilere gereken bilgileri sunan bir kaç sayfadan oluşan sitelerdir."},
  {id:"5",logo:"icons/store.svg",name:"Elektronik Ticaret", text:"Dükkanınızın 7/24 açık olması demektir. Bir çok kategori ile listelenmiş ürünlerin tanıtımını ve satışını gerçekleştiren sistemlerdir."},
  {id:"6",logo:"icons/like.svg",name:"Sosyal Ağ", text:"İnsanların kendi ya da gruplarının profillerini oluşturdukları, benzer ilgilerini, ortak amaçlarını, resimlerini, düşünlerini paylaştıkları sistemlerdir."},
  {id:"7",logo:"icons/game.svg",name:"Oyun", text:"Belirli bir senaryosu olan bireysel ve ya çoklu oyuncu desteği sunan  bir oyun arabirimi olan uygulamalardır."},
  {id:"8",logo:"icons/bookkeeper.svg",name:"E-Muhasebe", text:"Muhasebe işlemlerinizi kolaylaştıracak ve işlerinizi online internet üzerinden korntrol altında tutabileçeğiniz sistem"},
  {id:"9",logo:"icons/forum.svg",name:"Forum", text:"Çeşitli konu başlıkları altında insanların bilgi alışverişinde bulunduğu ve tartıştıkları web siteleridir."},
  {id:"10",logo:"icons/autonomy.svg",name:"Otomasyon Sistemi", text:"İşinizde her hangi bir işlemi otomatik dijital aktarmak isteğiniz zaman otomasyonleştirme işleminden yararlana bilirsiniz."},
  {id:"11",logo:"icons/depo.svg",name:"Depo Sistemi", text:"Deponuzda bulunan tüm ürünlerin giriş çıkışını güncel durumunu online  internet üzerinden kontrol atlınta tutabileçeğiniz sistemdir."},
  {id:"12",logo:"icons/rent.svg",name:"Kira Sistemi", text:"Platform sayesinde şirketinizdeki ürünleri kiraya teslim edip sonra geri alma işleminin	tamamını dijital ortamdan yönete bilirsiniz."},
  {id:"13",logo:"icons/cloud.svg",name:"Toplumsal", text:"Genel konularda topluma bilgi veren ve toplumdan bilgi toplayan sistemlerdir. Web & Mobil platformlarda esnek şekil almaktadır."},
  {id:"14",logo:"icons/remote.svg",name:"Uzaktan Erişim", text:"Uzak mesafeden isteğinize bağlı bağlantılar sayesinde erişib kontrol etmenizi sağlayan yazılımlar. Örneğin garaj kapısını açıp kapamak."},
  {id:"15",logo:"icons/school.svg",name:"Eğitim", text:"21. asırda artık eğitim internet üzerinden verilmektedir, ve web & mobil platformlar üzerinden insanlara gereken eğitimi sağlaya bilirsiniz"},
  {id:"16",logo:"icons/portal.svg",name:"Portal Siteleri", text:"Birçok konuda içerik sağlayan, günlük veya daha kısa sürede güncellenen ve zengin içerik sunan web siteleridir."},
  {id:"17",logo:"icons/sharing.svg",name:"Dosya Paylaşım", text:"Herhangi bir dosyayı, fotoğrafı veya belgeyi diğer insanlar ile paylaşmak istediğinizde dosya paylaşım siteleri ve mobil uygulamaları devreye girer."},
  {id:"18",logo:"icons/world.svg",name:"Haber", text:"Global veya Lokal gelişmeleri açık topluma sunan, dakikalık saatlık günlük yenilenen amacı topluma haber yaymak olan sistemlerdir."},
  {id:"19",logo:"icons/library.svg",name:"Dijital Kütüphane", text:"Kütüphane ortamının dijital hali olarak binlerce kitap dergi bilimsel çalışmaları kullanıcılara sunan yazılımdır."},
  {id:"20",logo:"icons/pencil.svg",name:"Blog", text:"Teknik bilgi gerektirmeden, kendi istedikleri şeyleri, kendi istedikleri şekilde yazan insanların oluşturdukları, günlüğe benzeyen internet siteleridir."},
  {id:"21",logo:"icons/cognitive.svg",name:"Bilgi", text:"Ziyaretçileri konu ile ilgili bilgilendirmeyi amaçlar. Devlet, eğitim ve kar amacı gütmeyen kuruluşlar bu gruba dahildir."}
]
export default ser_tr;
