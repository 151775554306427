const Social = (props) => {

  function gototel() {
    window.location.href = 'https://t.me/buartsoft';
  }

  function gotowp() {
    window.location.href = 'https://wa.me/905454813747';
  }

  return (
    <div className="container">
      <div className="contacts">
        <div className="col-12 row">
          <p className="socialslogan">{props.keepintouch}</p>

            <div className="col-md-6 contacts-item colright">
              <button type="button" onClick={gototel} class="socialHeadButton">
                <img className="socialmobileiconhead" src="icons/social/telegram.svg" alt=""/>
                &nbsp; Telegram  &nbsp;
              </button>
              </div>

              <div className="col-md-6 contacts-item colleft">
                <button type="button" onClick={gotowp} class="socialHeadButtonWhatsapp">
                  <img className="socialmobileiconhead" src="icons/social/whatsapp.svg" alt=""/>
                   &nbsp; Whatsapp 
                </button>
              </div>

        </div>
      </div>
    </div>
  );
}

export default Social;
