import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Languages from '../components/languages';
import Logopart from '../components/logopart';
import Social from '../components/social'
import Services from '../components/services';
import Partners from '../components/solution_partners';
import Contact from '../components/contact';
import Footer from '../components/footer';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_en from '../jsons/services/s_en';

class English extends React.Component {

  render(){
    return (
          <div>
                <Route exact path="/" component={() => <Languages lang="English"/>} />
                <Route exact path="/" component={() => <Logopart />}/>
                <Route exact path="/" component={() => <Social keepintouch={"keep in touch"}/>}/>
                <Route exact path="/" component={() => <Services langdata={ser_en} diname="services"/>} />
                <Route exact path="/" component={() => <Contact diname="contacts" loc1="Main office: Icmeler Mah. Altunay Sk. №: 33/33. Tuzla, Istanbul / Turkey" loc2="Soon: Dubai / UAE"/>} />
                <Route exact path="/" component={() => <Footer footerdata="All rights reserved" />} />
          </div>

    );
    }
}

export default English;
