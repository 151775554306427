import {Link} from "react-router-dom";

import React, {Component} from 'react';

class Languages extends Component {

    //
    // state = {
    //   isOpen: false
    // };
    // toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
    //
    // const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;

    render(){

      if (this.props.lang === "English") {
          var f = "Türkçe";
          var fl= "/tr";
          var s = "Русский";
          var sl = "/ru";
      }else if (this.props.lang === "Русский"){
          var f = "English";
          var fl= "/";
          var s = "Türkçe";
          var sl = "/tr";
      }else{
          var f = "English";
          var fl= "/";
          var s = "Русский";
          var sl = "/ru";
      }
            return (
            <div className="container d-flex flex-row-reverse">
                <div className="dropdown">
                    <button className="dropbtn" onClick={this.toggleOpen} type="button">{this.props.lang}</button>
                        <div className="dropdown-content">
                        <Link to={fl}>{f}</Link>
                        <Link to={sl}>{s}</Link>
                        </div>
                  </div>
            </div>
            );
    }
}

export default Languages;
