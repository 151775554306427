import '../App.css';
import React from 'react';
import {Route} from "react-router-dom";
import Languages from '../components/languages';
import Logopart from '../components/logopart';
import Services from '../components/services';
import Social from '../components/social'
import Partners from '../components/solution_partners';
import Contact from '../components/contact';
import Footer from '../components/footer';
import 'bootstrap/dist/css/bootstrap.min.css'

import ser_tr from '../jsons/services/s_tr';

class Turkish extends React.Component {

  render(){
    return (
          <div>
               <Route exact path="/tr" component={() => <Languages lang="Türkçe" />} />
               <Route exact path="/tr" component={() => <Logopart />} />
               <Route exact path="/tr" component={() => <Social keepintouch={"hızlı iletişim"}/>}/>
               <Route exact path="/tr" component={() => <Services key={ser_tr.map(sss => (sss.id))} langdata={ser_tr} diname="yazılım_hizmetlerimiz" />} />
               <Route exact path="/tr" component={() => <Contact diname="iletişim" loc1="Ana ofis: İçmeler Mah. Altunay Sk. №: 33/33. Tuzla, İstanbul / Türkiye" loc2="Yakında: Dubai / UAE"/>} />
               <Route exact path="/tr" component={() => <Footer footerdata="Tüm haklar saklıdır" />} />
          </div>

    );
    }
}

export default Turkish;
